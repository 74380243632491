<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_slots')"
                        :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_slots')"
                              :isFilter="false">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('first_name')">
                            <b-form-input
                                v-model="
                                                datatable.queryParams.filter
                                                    .name
                                            "
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('last_name')">
                            <b-form-input
                                v-model="
                                                datatable.queryParams.filter
                                                    .surname
                                            "
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group
                            :label="$t('number_of_semesters')"
                        >
                            <period-selectbox
                                v-model="
                                                datatable.queryParams.filter
                                                    .number_of_semesters
                                            "
                            ></period-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('school')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                v-model="
                                                datatable.queryParams.filter
                                                    .department_code
                                            "
                                :faculty_code="
                                                datatable.queryParams.filter
                                                    .faculty_code
                                            "
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                v-model="
                                                datatable.queryParams.filter
                                                    .program_code
                                            "
                                :faculty_code="
                                                datatable.queryParams.filter
                                                    .faculty_code
                                            "
                                :department_code="
                                                datatable.queryParams.filter
                                                    .department_code
                                            "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('letter_grade')">
                            <grade-selectbox
                                :grading-system-id-required="false"
                                v-model="datatable.queryParams.filter.letter_grade"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.registration_academic_year" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <div class="d-flex justify-content-end mb-2" v-if="excelData.length > 0">
                <json-excel :data="excelData"
                            :fields="excelFields"
                            type="xls"
                >
                    <b-button variant="primary" class="mr-2">
                        <i class="ri-file-excel-2-line mr-2"></i> {{ $t('excel') }}
                    </b-button>
                </json-excel>
            </div>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
//Service
import StudentSlotService from "@/services/StudentSlotService";
//Other
import JsonExcel from "vue-json-excel";
import qs from "qs";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox.vue";
import PeriodSelectbox from "@/components/interactive-fields/PeriodSelectbox.vue";
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox.vue";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";

export default {
    components: {
        AcademicYearsSelectbox,
        GradeSelectbox,
        PeriodSelectbox,
        YesNoSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        DepartmentSelectbox,
        FacultySelectbox,
        ProgramSelectbox,

        JsonExcel
    },
    metaInfo() {
        return {
            title: this.$t("student_slots"),
        };
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            excelData: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [],
                    },
                    {
                        label: this.$t("id").toUpper(),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("slot_type").toUpper(),
                        field: "slotType.name",
                        sortable: true,
                    },
                    {
                        label: this.$t("course").toUpper(),
                        field: "course_name",
                        sortable: true,
                    },
                    {
                        label: this.$t("course_code").toUpper(),
                        field: "course_code",
                        sortable: true,
                    },
                    {
                        label: this.$t("instructor").toUpper(),
                        field: "instructor",
                        sortable: true,
                    },
                    {
                        label: this.$t("semester").toUpper(),
                        field: "last_course_semester",
                        sortable: true,
                    },
                    {
                        label: this.$t("letter_grade").toUpper(),
                        field: "letter_grade",
                        sortable: true,
                    },
                    {
                        label: this.$t("credits_attempted").toUpper(),
                        field: "credit",
                        sortable: true,
                    },
                    {
                        label: this.$t("registration_academic_year").toUpper(),
                        field: "registration_academic_year",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            excelFields: {
                [this.$t('id')]: 'id',
                [this.$t('name')]: 'name',
                [this.$t('surname')]: 'surname',
                [this.$t('registration_academic_year')]: 'registration_academic_year',
                [this.$t('registration_type')]: 'registration_type',
                [this.$t('credits_attempted')]: 'credit',
                [this.$t('last_course_credit')]: 'last_course_credit',
                [this.$t('course_name')]: 'course_name',
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                    sort: 'id'
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return StudentSlotService.getSlots(config)
                .then((response) => {
                    this.excelData = [...response.data.data];
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
    },
};
</script>
